
import { defineComponent } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import CuleForm from './components/ClueForm/ClueForm.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, loadPage } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'ClientList',
  components: { CrmList, CuleForm },
  props: {},
  setup() {
    const { filterOptions, columnList, tableRef } = useListData(1);
    const { visible, showVisible } = useVisible();
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      loadPage('ContactDetails', {
        id: row.id,
        type: 1, // 1客户 2客户池 3回收站
      });
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable();
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      closeVisible,
      rowClick,
      getLoadPage,
    };
  },
});
